import axios from 'axios';
import template from 'url-template';
import queryString from 'query-string';
import { config } from '../config/config';
import {
  getClientId,
  getNetworkElementId,
  getNextRoute,
  getAppRouteForEnv,
  APPLICATION_ENUMS,
  blobToJson,
  getQueryParams,
} from '../utils/utils';

//  Action Creators and Consts

export const ROUTES = {
  FORGOT_PASSWORD: '/reset_password',
  RESET_PASSWORD: template.parse('/reset_password/{token}'),
  CHANGE_PASSWORD: '/login/change_password',
  INIT_PASSWORD: '/init_password',
  LOGIN: '/login/auth',
  TOKEN_LOGIN: '/oauth/token',
  SAML_LOGIN: template.parse('/{orgShortName}/sso/login'),
  APPEARANCE_SETTINGS: template.parse('/appearance/{orgShortName}/login_page'),
  META_IDP_LOGIN: template.parse('/apps/{appId}/sso/saml'),
  AAC_LOGIN: template.parse('/apps/{appId}/aac/saml'),
  DIRECT_APP_SSO: template.parse('/apps/{appId}/sso_deprecated'),
  AUTHORIZE: '/login/authorize',
  VERIFY_PIN: '/login/factors/verify',
  SEND_CHALLENGE: '/login/factors/challenge',
  SKIP_ENROLL: '/login/factors/skip',
  SET_FACTOR: '/login/factors',
  ACTIVATE_FACTOR: '/login/factors/activate',
  FORGOT_ORG: '/find_my_orgs',
  OAUTH_INFO: '/oauth/info',
  REPORTS: '/reports',
  ENFORCER_ENRICHMENT: '/aac_rules/enforcer',
  ISOLATE_APP: '/apps/sso',
  LOGIN_SSO_OIDC_REQUEST: '/apps/sso/oidc',
};

export const ACTION_NAMES = {
  // Login
  LOGIN_INITIAL_STATE: 'LOGIN_INITIAL_STATE',
  CHECK_EXISTING_TOKEN_START: 'CHECK_EXISTING_TOKEN_START',
  CHECK_EXISTING_TOKEN_FINISH: 'CHECK_EXISTING_TOKEN_FINISH',

  CHECK_SAML_EXIST_START: 'CHECK_SAML_EXIST_START',
  CHECK_SAML_EXIST_FINISH: 'CHECK_SAML_EXIST_FINISH',

  GET_APPEARANCE_SETTINGS_START: 'GET_APPEARANCE_SETTINGS_START',
  GET_APPEARANCE_SETTINGS_FINISH: 'GET_APPEARANCE_SETTINGS_FINISH',

  AUTHORIZE_REQUEST_START: 'AUTHORIZE_REQUEST_START',
  AUTHORIZE_REQUEST_SUCCESS: 'AUTHORIZE_REQUEST_SUCCESS',
  AUTHORIZE_REQUEST_FAIL: 'AUTHORIZE_REQUEST_FAIL',

  LOGIN_REQUEST_START: 'LOGIN_REQUEST_START',
  LOGIN_REQUEST_SUCCESS: 'LOGIN_REQUEST_SUCCESS',
  LOGIN_REQUEST_FAIL: 'LOGIN_REQUEST_FAIL',
  LOGIN_WITH_2FA: 'LOGIN_WITH_2FA',

  REDIRECT_TO_ORG_LOGIN_START: 'REDIRECT_TO_ORG_LOGIN_START',
  REDIRECT_TO_ORG_LOGIN_FINISH: 'REDIRECT_TO_ORG_LOGIN_FINISH',

  GO_TO_PASSWORD_LOGIN: 'GO_TO_PASSWORD_LOGIN',

  SAML_LOGIN_REQUEST_START: 'SAML_LOGIN_REQUEST_START',
  SAML_LOGIN_REQUEST_SUCCESS: 'SAML_LOGIN_REQUEST_SUCCESS',
  SAML_LOGIN_REQUEST_FAIL: 'SAML_LOGIN_REQUEST_FAIL',

  SEND_PIN_REQUEST_START: 'SEND_PIN_REQUEST_START',
  SEND_PIN_REQUEST_SUCCESS: 'SEND_PIN_REQUEST_SUCCESS',
  SEND_PIN_REQUEST_FAIL: 'SEND_PIN_REQUEST_FAIL',

  VERIFY_PIN_INITIAL_STATE: 'VERIFY_PIN_INITIAL_STATE',
  VERIFY_PIN_REQUEST_START: 'VERIFY_PIN_REQUEST_START',
  VERIFY_PIN_REQUEST_SUCCESS: 'VERIFY_PIN_REQUEST_SUCCESS',
  VERIFY_PIN_REQUEST_FAIL: 'VERIFY_PIN_REQUEST_FAIL',

  CHANGE_VERIFICATION_METHOD: 'CHANGE_VERIFICATION_METHOD',
  TOGGLE_VERIFICATION_METHODS_MODAL: 'TOGGLE_VERIFICATION_METHODS_MODAL',

  CHECK_REMEMBER_ME_AVAILABLE_START: 'CHECK_REMEMBER_ME_AVAILABLE_START',
  CHECK_REMEMBER_ME_AVAILABLE_FINISH: 'CHECK_REMEMBER_ME_AVAILABLE_FINISH',

  // Forgot Org
  FORGOT_ORG_INITIAL_STATE: 'FORGOT_ORG_INITIAL_STATE',
  FORGOT_ORG_REQUEST_START: 'FORGOT_ORG_REQUEST_START',
  FORGOT_ORG_REQUEST_SUCCESS: 'FORGOT_ORG_REQUEST_SUCCESS',
  FORGOT_ORG_REQUEST_FAIL: 'FORGOT_ORG_REQUEST_FAIL',

  // Forgot Password
  FORGOT_PASSWORD_INITIAL_STATE: 'FORGOT_PASSWORD_INITIAL_STATE',
  FORGOT_PASSWORD_REQUEST_START: 'FORGOT_PASSWORD_REQUEST_START',
  FORGOT_PASSWORD_REQUEST_SUCCESS: 'FORGOT_PASSWORD_REQUEST_SUCCESS',
  FORGOT_PASSWORD_REQUEST_FAIL: 'FORGOT_PASSWORD_REQUEST_FAIL',

  // Reset Password
  RESET_PASSWORD_INITIAL_STATE: 'RESET_PASSWORD_INITIAL_STATE',
  RESET_PASSWORD_REQUEST_START: 'RESET_PASSWORD_REQUEST_START',
  RESET_PASSWORD_REQUEST_SUCCESS: 'RESET_PASSWORD_REQUEST_SUCCESS',
  RESET_PASSWORD_REQUEST_FAIL: 'RESET_PASSWORD_REQUEST_FAIL',

  // Signup - General
  GET_STATE_TOKEN_SUCCESS: 'GET_STATE_TOKEN_SUCCESS',
  GET_USER_PHONE_SUCCESS: 'GET_USER_PHONE_SUCCESS',

  // Signup - Set Email
  SET_EMAIL_INITIAL_STATE: 'SET_EMAIL_INITIAL_STATE',
  SET_EMAIL_REQUEST_START: 'SET_EMAIL_REQUEST_START',
  SET_EMAIL_REQUEST_SUCCESS: 'SET_EMAIL_REQUEST_SUCCESS',
  SET_EMAIL_REQUEST_FAIL: 'SET_EMAIL_REQUEST_FAIL',
  BACK_TO_LOGIN_CLICKED: 'BACK_TO_LOGIN_CLICKED',

  // Signup - Verify Email
  VERIFY_EMAIL_INITIAL_STATE: 'VERIFY_EMAIL_INITIAL_STATE',
  VERIFY_EMAIL_REQUEST_START: 'VERIFY_EMAIL_REQUEST_START',
  VERIFY_EMAIL_REQUEST_SUCCESS: 'VERIFY_EMAIL_REQUEST_SUCCESS',
  VERIFY_EMAIL_REQUEST_FAIL: 'VERIFY_EMAIL_REQUEST_FAIL',

  // Signup - Set Password
  SET_PASSWORD_INITIAL_STATE: 'SET_PASSWORD_INITIAL_STATE',
  SET_PASSWORD_REQUEST_START: 'SET_PASSWORD_REQUEST_START',
  SET_PASSWORD_REQUEST_SUCCESS: 'SET_PASSWORD_REQUEST_SUCCESS',
  SET_PASSWORD_REQUEST_FAIL: 'SET_PASSWORD_REQUEST_FAIL',
  SET_PASSWORD_TOKEN_EXPIRED: 'SET_PASSWORD_TOKEN_EXPIRED',

  // Signup - Set Phone
  SET_PHONE_INITIAL_STATE: 'SET_PHONE_INITIAL_STATE',
  SET_PHONE_REQUEST_START: 'SET_PHONE_REQUEST_START',
  SET_PHONE_REQUEST_SUCCESS: 'SET_PHONE_REQUEST_SUCCESS',
  SET_PHONE_REQUEST_FAIL: 'SET_PHONE_REQUEST_FAIL',

  // Signup - Set Authenticator
  SET_AUTHENTICATOR_INITIAL_STATE: 'SET_AUTHENTICATOR_INITIAL_STATE',
  SET_AUTHENTICATOR_REQUEST_START: 'SET_AUTHENTICATOR_REQUEST_START',
  SET_AUTHENTICATOR_REQUEST_SUCCESS: 'SET_AUTHENTICATOR_REQUEST_SUCCESS',
  SET_AUTHENTICATOR_REQUEST_FAIL: 'SET_AUTHENTICATOR_REQUEST_FAIL',
  GET_AUTHENTICATOR_CODE_SUCCESS: 'GET_AUTHENTICATOR_CODE_SUCCESS',
  GET_AUTHENTICATOR_CODE_FAIL: 'GET_AUTHENTICATOR_CODE_FAIL',

  // select mfa
  SELECT_MFA_INITIAL_STATE: 'SELECT_MFA_INITIAL_STATE',
  SELECT_MFA_GET_FACTORS_SUCCESS: 'SELECT_MFA_GET_FACTORS_SUCCESS',
  SELECT_MFA_FACTOR_SELECTED: 'SELECT_MFA_FACTOR_SELECTED',
  SELECT_MFA_RESET_STATE: 'SELECT_MFA_RESET_STATE',
  SEND_PHONE_CHALLENGE_SUCCESS: 'SEND_PHONE_CHALLENGE_SUCCESS',
  SEND_PHONE_CHALLENGE_FAIL: 'SEND_PHONE_CHALLENGE_FAIL',
  SELECT_MFA_SKIP_ENROLL_ERROR: 'SELECT_MFA_SKIP_ENROLL_ERROR',

  // verify mfa - phone
  VERIFY_PHONE_INITIAL_STATE: 'VERIFY_PHONE_INITIAL_STATE',

  // verify mfa - authenticator
  VERIFY_AUTHENTICATOR_INITIAL_STATE: 'VERIFY_FACTOR_INITIAL_STATE',

  // verify mfa
  VERIFY_FACTOR_REQUEST_START: 'VERIFY_FACTOR_REQUEST_START',
  VERIFY_FACTOR_REQUEST_SUCCESS: 'VERIFY_FACTOR_REQUEST_SUCCESS',
  VERIFY_FACTOR_REQUEST_FAIL: 'VERIFY_FACTOR_REQUEST_FAIL',

  //  app view
  APP_VIEW_INITIAL_STATE: 'APP_VIEW_INITIAL_STATE',
  APP_VIEW_PAGE_LOADED: 'APP_VIEW_PAGE_LOADED',

  //  aac app view
  AAC_APP_VIEW_INITIAL_STATE: 'AAC_APP_VIEW_INITIAL_STATE',
  AAC_APP_VIEW_PAGE_LOADED: 'AAC_APP_VIEW_PAGE_LOADED',

  GET_TOKEN_FROM_COOKIE_REQUEST_START: 'GET_TOKEN_FROM_COOKIE_REQUEST_START',
  GET_TOKEN_FROM_COOKIE_REQUEST_SUCCESS: 'GET_TOKEN_FROM_COOKIE_REQUEST_SUCCESS',
  GET_TOKEN_FROM_COOKIE_REQUEST_FAIL: 'GET_TOKEN_FROM_COOKIE_REQUEST_FAIL',

  LOGIN_WITH_META_IDP_REQUEST_START: 'LOGIN_WITH_META_IDP_REQUEST_START',
  LOGIN_WITH_META_IDP_REQUEST_SUCCESS: 'LOGIN_WITH_META_IDP_REQUEST_SUCCESS',
  LOGIN_WITH_META_IDP_REQUEST_FAIL: 'LOGIN_WITH_META_IDP_REQUEST_FAIL',

  LOGIN_WITH_AAC_REQUEST_START: 'LOGIN_WITH_AAC_REQUEST_START',
  LOGIN_WITH_AAC_REQUEST_SUCCESS: 'LOGIN_WITH_AAC_REQUEST_SUCCESS',
  LOGIN_WITH_AAC_REQUEST_FAIL: 'LOGIN_WITH_AAC_REQUEST_FAIL',

  GET_DIRECT_SSO_IDP_REQUEST_START: 'GET_DIRECT_SSO_IDP_REQUEST_START',
  GET_DIRECT_SSO_IDP_REQUEST_SUCCESS: 'GET_DIRECT_SSO_IDP_REQUEST_SUCCESS',
  GET_DIRECT_SSO_IDP_REQUEST_FAIL: 'LOGIN_WITH_META_IDP_REQUEST_FAIL',

  GET_TOKEN_FROM_OTP_REQUEST_START: 'GET_TOKEN_FROM_OTP_REQUEST_START',
  GET_TOKEN_FROM_OTP_REQUEST_SUCCESS: 'GET_TOKEN_FROM_OTP_REQUEST_SUCCESS',
  GET_TOKEN_FROM_OTP_REQUEST_FAIL: 'GET_TOKEN_FROM_OTP_REQUEST_FAIL',

  REDIRECT_TO_META_IDP_START: 'REDIRECT_TO_META_IDP_START',
  REDIRECT_TO_META_IDP_FINISH: 'REDIRECT_TO_META_IDP_FINISH',
  // Error
  ERROR_PAGE_FETCHED_PARAMS: 'ERROR_PAGE_FETCHED_PARAMS',
  RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE',
  WRONG_STATUS: 'WRONG_STATUS',
  PASSWORD_EXPIRED: 'PASSWORD_EXPIRED',
  // blocked page
  BLOCKED_PAGE_FETCH_PARAMS: 'BLOCKED_PAGE_FETCH_PARAMS',

  GET_OAUTH_INFO_START: 'GET_OAUTH_INFO_START',
  GET_OAUTH_INFO_SUCCESS: 'GET_OAUTH_INFO_SUCCESS',
  GET_OAUTH_INFO_FAIL: 'GET_OAUTH_INFO_FAIL',

  REPORTS_CONTAINER_PAGE_LOADED: 'REPORTS_CONTAINER_PAGE_LOADED',

  DOWNLOAD_REPORT_START: 'DOWNLOAD_REPORT_START',
  DOWNLOAD_REPORT_SUCCESS: 'DOWNLOAD_REPORT_SUCCESS',
  DOWNLOAD_REPORT_FAIL: 'DOWNLOAD_REPORT_FAIL',

  ENFORCER_START: 'ENFORCER_START',
  ENFORCER_REDIRECT_SUCCESS: 'ENFORCER_REDIRECT_SUCCESS',
  ENFORCER_SAML_SUCCESS: 'ENFORCER_SAML_SUCCESS',
  ENFORCER_FAIL: 'ENFORCER_FAIL',
  APP_ISOLATE_START: 'APP_ISOLATE_START',
  APP_ISOLATE_SUCCESS: 'APP_ISOLATE_SUCCESS',
  APP_ISOLATE_FAIL: 'APP_ISOLATE_FAIL',

  OIDC_VIEW_PAGE_LOADED: 'OIDC_VIEW_PAGE_LOADED',
  OIDC_VIEW_INITIAL_STATE: 'OIDC_VIEW_INITIAL_STATE',
  GET_OIDC_REDIRECT: 'GET_OIDC_REDIRECT',
  LOGIN_WITH_OIDC_SUCCESS: 'LOGIN_WITH_OIDC_SUCCESS',
  LOGIN_WITH_OIDC_FAIL: 'LOGIN_WITH_OIDC_FAIL',
  APP_ISOLATE_OIDC_SUCCESS: 'APP_ISOLATE_OIDC_SUCCESS',
};

export const getUrlParameter = (name = '', isCheckOnHref) => {
  if (!name) {
    return '';
  }
  const nameParam = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${nameParam}=([^&]*)`);
  const urlQuery = isCheckOnHref ? window.location.href : window.location.search;
  const results = regex.exec(urlQuery);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};


export const VerificationMethodsEnum = {
  SOFTWARE_TOTP: 'SOFTWARE_TOTP',
  SMS: 'SMS',
  VOICECALL: 'VOICECALL',
  PASSWORD: 'password',
  EMAIL: 'EMAIL',
  UNKNOWN: 'unknown',
};

function sendPasswordInvite(email) {
  const baseUrl = config.server;
  const route = ROUTES.FORGOT_PASSWORD;
  const reqData = {
    email,
    org: config.org_short_name,
  };

  return axios.post(`${baseUrl}${route}`, reqData);
}

function setNewPassword(password, token) {
  const baseUrl = config.server;
  const route = ROUTES.RESET_PASSWORD.expand({ token });
  const reqData = {
    password,
  };

  return axios.post(`${baseUrl}${route}`, reqData);
}

// Replace an expired password for the user
function changePassword(password, token) {
  const baseUrl = config.server;
  const route = ROUTES.CHANGE_PASSWORD;

  return axios({
    method: 'POST',
    url: `${baseUrl}${route}`,
    headers: { authorization: `Bearer ${token}` },
    data: { password },
  });
}

function getNewToken(refreshToken) {
  const baseUrl = config.server;
  const route = ROUTES.TOKEN_LOGIN;
  const reqData = {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    scope: `org:${config.eorg_short_name || config.org_short_name}`,
  };
  return axios.post(`${baseUrl}${route}`, reqData);
}

function loginWithCode(code) {
  const baseUrl = config.server;
  const route = ROUTES.TOKEN_LOGIN;
  const reqData = {
    grant_type: 'authorization_code',
    client_id: 'portal',
    code,
    scope: `org:${config.eorg_short_name || config.org_short_name}`,
  };
  return axios.post(`${baseUrl}${route}`, reqData);
}

// Generate 6 digits password for the new user + send email
function initPassword(email) {
  const baseUrl = config.server;
  const route = ROUTES.INIT_PASSWORD;
  const reqData = {
    email,
    org: config.org_short_name,
  };

  return axios.post(`${baseUrl}${route}`, reqData);
}

function setFactorAuthenticationMethod(token, reqData) {
  const baseUrl = config.server;
  const route = ROUTES.SET_FACTOR;

  return axios({
    method: 'POST',
    url: `${baseUrl}${route}`,
    headers: { authorization: `Bearer ${token}` },
    data: reqData,
  });
}

function login(email, password) {
  const baseUrl = config.server;
  const route = ROUTES.LOGIN;
  const reqData = {
    password,
    username: email,
    org_shortname: config.org_short_name,
  };

  return axios.post(`${baseUrl}${route}`, reqData);
}

function loginWithSaml(pkceChallenge) {
  const baseUrl = config.server;
  const orgShortName = config.org_short_name;
  const pkce = pkceChallenge ? `&code_challenge=${pkceChallenge}&code_challenge_method=S256` : '';
  const route = ROUTES.SAML_LOGIN.expand({ orgShortName, pkce });
  const nextRoute = getNextRoute();
  const next = encodeURIComponent(nextRoute.nextUrl);
  const clientId = getClientId();
  // Only include clientId if it arrives from URL
  const clientIdFromUrl = getUrlParameter('clientId');
  let url = `${baseUrl}${route}?next=${next}${pkce}`;
  let networkElementId = '';

  if (clientId === APPLICATION_ENUMS.OVERLAY) {
    networkElementId = getNetworkElementId();
    if (networkElementId) {
      url += `&network_element_id=${networkElementId}`;
    }
  }
  if (clientIdFromUrl) {
    url += `&client_id=${clientIdFromUrl}`;
  }

  return axios.get(url);
}

function getAppearanceSettings() {
  const baseUrl = config.server;
  const orgShortName = config.org_short_name;
  const route = ROUTES.APPEARANCE_SETTINGS.expand({ orgShortName });
  return axios.get(`${baseUrl}${route}`);
}

function skipEnroll(stateToken) {
  const baseUrl = config.server;
  const route = ROUTES.SKIP_ENROLL;
  const options = {
    headers: {
      Authorization: `Bearer ${stateToken}`,
    },
  };

  return axios.post(`${baseUrl}${route}`, null, options);
}

function authorize(clientId, networkElementId, sessionToken, pkceChallenge, redirectUri) {
  const baseUrl = config.server;
  const route = ROUTES.AUTHORIZE;
  const params = {
    response_type: 'code',
    client_id: clientId,
  };

  if (networkElementId) {
    params.network_element_id = networkElementId;
  }

  if (pkceChallenge) {
    params.code_challenge = pkceChallenge;
    params.code_challenge_method = 'S256';
  }
  if (redirectUri) {
    params.redirect_uri = redirectUri;
  }

  const headers = {
    Authorization: `Bearer ${sessionToken}`,
  };

  return axios.get(`${baseUrl}${route}`, {
    params,
    headers,
  });
}

function redirectToOrgLogin(orgShortName) {
  const query = window.location.search || '';
  //  Resolve a case where trying to redirect to unknown login org after successful login
  const fixedQuery = query.replace('https%3A%2F%2Flogin', `https%3A%2F%2F${orgShortName}`);
  const envRoute = getAppRouteForEnv(orgShortName);
  window.location.href = `${envRoute}/login/${fixedQuery}`;
}

function loginWithMetaIdP(appId, accessToken, query = '') {
  const parsedQueryParams = queryString.parse(query);
  const encodedQueryParams = queryString.stringify({
    RelayState: parsedQueryParams.RelayState,
    SAMLRequest: parsedQueryParams.SAMLRequest,
    opaque: parsedQueryParams.opaque,
  });

  const baseUrl = config.server;
  const queryParamsString = encodedQueryParams.length ? `?${encodedQueryParams}` : '';
  const route = `${ROUTES.META_IDP_LOGIN.expand({ appId })}${queryParamsString}`;
  const options = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios.get(`${baseUrl}${route}`, options);
}

function getDirectSSOForApplication(appId) {
  const baseUrl = config.server;
  const route = `${ROUTES.DIRECT_APP_SSO.expand({ appId })}`;

  return axios.get(`${baseUrl}${route}`);
}

const getReport = async (reportId, reportFormat = 'csv', accessToken) => {
  const baseUrl = config.server;
  const route = `${ROUTES.REPORTS}/${reportId}?report_format=${reportFormat}`;
  const options = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: 'blob',
  };
  try {
    const res = await axios.get(`${baseUrl}${route}`, options);
    return res;
  } catch (err) {
    if (err.response) {
      const data = await blobToJson(err?.response?.data);
      if (data) {
        const error = { data };
        throw error;
      }
    }
    throw err;
  }
};
function loginWithAac(appId, query = '') {
  const parsedQueryParams = queryString.parse(query);
  const queryParamsObject = {};
  if (parsedQueryParams.aac_token) {
    queryParamsObject.aac_token = parsedQueryParams.aac_token;
  }
  if (parsedQueryParams.RelayState) {
    queryParamsObject.RelayState = parsedQueryParams.RelayState;
  }
  if (parsedQueryParams.LoginHint) {
    queryParamsObject.LoginHint = parsedQueryParams.LoginHint;
  }
  const encodedQueryParams = queryString.stringify(queryParamsObject);

  const baseUrl = config.server;
  const queryParamsString = encodedQueryParams.length ? `?${encodedQueryParams}` : '';
  const route = `${ROUTES.AAC_LOGIN.expand({ appId })}${queryParamsString}`;

  return axios.get(`${baseUrl}${route}`);
}

function loginWithOIDC(accessToken) {
  const parsedQueryParams = getQueryParams();
  const encodedQueryParams = queryString.stringify(parsedQueryParams);

  const baseUrl = config.server;
  const queryParamsString = encodedQueryParams.length ? `?${encodedQueryParams}` : '';
  const route = `${baseUrl}${ROUTES.LOGIN_SSO_OIDC_REQUEST}${queryParamsString}`;

  return axios.get(`${route}`, { headers: { Authorization: `Bearer ${accessToken}` } });
}

function sendChallenge(method, stateToken) {
  const baseUrl = config.server;
  const route = ROUTES.SEND_CHALLENGE;
  const reqData = {
    factor_type: method,
  };
  const options = {
    headers: {
      Authorization: `Bearer ${stateToken}`,
    },
  };

  return axios.post(`${baseUrl}${route}`, reqData, options);
}

function verifyPin(pinCode, stateToken, verificationMethod, endpoint) {
  const baseUrl = config.server;
  const route = endpoint || ROUTES.VERIFY_PIN;
  const reqData = {
    factor_type: verificationMethod,
    otp_code: pinCode,
  };

  const options = {
    headers: {
      Authorization: `Bearer ${stateToken}`,
    },
  };

  return axios.post(`${baseUrl}${route}`, reqData, options);
}

function forgotOrg(email) {
  const baseUrl = config.server;
  const route = `${ROUTES.FORGOT_ORG}`;

  const reqData = {
    email,
    from_app: Boolean(getUrlParameter('app').length),
  };

  return axios.post(`${baseUrl}${route}`, reqData);
}

const getOAuthInfo = accessToken => {
  const baseUrl = config.server;
  const route = ROUTES.OAUTH_INFO;
  return axios.get(`${baseUrl}${route}`, { headers: { Authorization: `Bearer ${accessToken}` } });
};

const getRealm = () => {
  const baseUrl = config.server;
  const orgShortName = config.org_short_name;
  const route = `/orgs/${orgShortName}/realm`;
  return axios.get(`${baseUrl}${route}`);
};

const getEnforcerEnrich = queryParams => {
  const baseUrl = config.server;
  const route = ROUTES.ENFORCER_ENRICHMENT;
  return axios.get(`${baseUrl}${route}${queryParams}`);
};

const getAppIsolate = queryParams => {
  const baseUrl = config.server;
  const route = ROUTES.ISOLATE_APP;
  return axios.get(`${baseUrl}${route}${queryParams}`);
};

export {
  authorize,
  skipEnroll,
  login,
  loginWithSaml,
  getAppearanceSettings,
  getEnforcerEnrich,
  getAppIsolate,
  sendPasswordInvite,
  sendChallenge,
  setNewPassword,
  verifyPin,
  initPassword,
  changePassword,
  setFactorAuthenticationMethod,
  forgotOrg,
  getNewToken,
  loginWithCode,
  loginWithMetaIdP,
  getReport,
  loginWithAac,
  loginWithOIDC,
  getDirectSSOForApplication,
  getOAuthInfo,
  getRealm,
  redirectToOrgLogin,
};
