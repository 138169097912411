import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { getCookie } from '../../utils/utils';
import MetaLoader from '../../components/meta-loader';
import { NSOF_REFRESH_TOKEN } from '../../consts/general-consts';
import { ROUTES } from '../../consts/routes';
import { getTokenFromCookie, getTokenFromOtp } from '../../redux/action-creators';
import { loginWithMetaIdp, onAppViewLoaded, getDirectSSOAndRedirectToLogin } from './app-view-state';
import './app-view-container.sass';

class AppViewContainer extends Component {
  componentDidMount() {
    const parsedQueryParams = queryString.parse(window.location.search);
    const existingCookie = getCookie(NSOF_REFRESH_TOKEN);
    if (parsedQueryParams.code) {
      this.props.getTokenFromOtp(parsedQueryParams.code);
    } else if (existingCookie && this.props.enableCookie) {
      this.props.getTokenFromCookie(existingCookie);
    } else {
      this.props.onAppViewLoaded();
    }
  }

  render() {
    const {
      accessToken,
      loading,
      idpRequestSuccessful,
      SAMLResponse,
      RelayState,
      destinationURL,
      idpLoginError,
      idpLoginErrorMessage,
      redirectToLoginUrl,
      redirectURL,
    } = this.props;

    if (loading) {
      return <div />;
    }

    if (idpRequestSuccessful && redirectURL) {
      // Redirecting to the aac URL received which is returned by 202 from login
      window.location.href = redirectURL;
      return <div />;
    }

    if (idpRequestSuccessful) {
      setTimeout(() => {
        if (document.metaIdpForm) {
          document.metaIdpForm.submit();
        }
      }, 1000);
      return (
        <form name="metaIdpForm" action={destinationURL} method="post">
          <input type="hidden" name="SAMLResponse" value={SAMLResponse} />
          <input type="hidden" name="RelayState" value={RelayState} />
          <MetaLoader />
        </form>
      );
    }

    const id = window.location.pathname.split('/').pop();
    if (redirectToLoginUrl) {
      const { direct_sso_login } = redirectToLoginUrl;
      const invokeToSSO = direct_sso_login ? `invokeLoginWithSSO=${encodeURIComponent(direct_sso_login)}&` : '';
      const redirectUrl = `${ROUTES.LOGIN}?${invokeToSSO}next=${encodeURIComponent(window.location.href)}`;
      return (
        <Redirect
          to={redirectUrl}
        />
      );
    }
    if (accessToken && !idpLoginError) {
      const query = window.location.search;
      this.props.loginWithMetaIdp(id, accessToken, query);
    } else if (!accessToken && !idpLoginError) {
      this.props.getDirectSSOAndRedirectToLogin(id);
    } else if (idpLoginError) {
      const { title, detail, request_id: requestId } = idpLoginError;
      return (
        <Redirect
          to={{
            pathname: `${ROUTES.ERROR_PAGE}/?title=${encodeURIComponent(
              title,
            )}&reason=${encodeURIComponent(detail)}&request_id=${encodeURIComponent(requestId)}`,
          }}
        />
      );
    }

    return (
      <div className="app-page-container">
        {!idpLoginErrorMessage && <MetaLoader />}
        <div>{idpLoginErrorMessage}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.appViewState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTokenFromCookie,
      getTokenFromOtp,
      loginWithMetaIdp,
      getDirectSSOAndRedirectToLogin,
      onAppViewLoaded,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AppViewContainer);
